import React from 'react';
import LoginForm from '../../components/loginForm/LoginForm';

function login() {
  return (
    <section>
      <LoginForm />
    </section>
  );
}

export default login;
