import React from 'react';
import RegistrationForm from '../../components/registrationForm/RegistrationForm';

function Registration() {
  return (
    <section>
      <RegistrationForm />
    </section>
  );
}

export default Registration;
