import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getBlogsRequest,
  postLikeRequest,
  postDisLikeRequest,
  abortConst,
} from '../../../redux';
import './HomeBlog.scss';
import SearchInput from '../../searchInput/SearchInput';
import moment from 'moment';
import Modal from '../../modal/Modal';

function HomeBlog({
  blogs,
  getBlogsRequest,
  postLikeRequest,
  postDisLikeRequest,
}) {
  const [searchField, setSearchField] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    getBlogsRequest();
    return () => {
      abortConst.abort();
    };
  }, [getBlogsRequest]);

  const timeStamp = (date, format) => {
    return moment(date, format).fromNow();
  };

  const filteredBlogs = blogs.blogs.filter((blog) => {
    return blog.heading.toLowerCase().includes(searchField.toLowerCase());
  });

  const handleLike = (id, name, heading, message, posted, likes) => {
    const data = {
      id: id,
      name: name,
      heading: heading,
      message: message,
      posted: posted,
      likes: parseInt(likes) + 1,
    };
    postLikeRequest(data);
  };

  const handleDisLike = (id, name, heading, message, posted, dislikes) => {
    const data = {
      id: id,
      name: name,
      heading: heading,
      message: message,
      posted: posted,
      dislikes: parseInt(dislikes) + 1,
    };
    postDisLikeRequest(data);
  };

  const matchName = (current) => {
    let reggie = new RegExp(searchField, 'ig');
    let found = current.search(reggie) !== -1;
    return !found
      ? current
      : current.replace(
          reggie,
          '<span style="color:greenYellow; background-color:rgba(51,51,51,1)" >' +
            searchField +
            '</span>',
        );
  };

  // Modal Stuff
  const modalContent = (
    id,
    heading,
    message,
    posted,
    name,
    likes,
    dislikes,
    admin,
  ) => {
    return (
      <div>
        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
        <p dangerouslySetInnerHTML={{ __html: message }} />

        <p className="userDetails">
          Post by: {name} {timeStamp(posted, 'YYYYMMDD, h:mm:ss a')}
        </p>
        <hr />
        <div className="clap-wrapper">
          <div>
            <span
              className="likes"
              onClick={() =>
                handleLike(id, name, heading, message, posted, likes)
              }
            >
              <i className="far fa-thumbs-up"></i>
              <span className="text-small">{likes}</span>
            </span>
          </div>
          <div>
            <span
              className="dislikes"
              onClick={() =>
                handleDisLike(id, name, heading, message, posted, dislikes)
              }
            >
              {' '}
              <span className="text-small">{dislikes}</span>
              <i className="far fa-thumbs-down"></i>
            </span>
          </div>
        </div>
        <hr />
        {admin === '1' ? (
          <div className="adminMessage">
            <i className="fas fa-exclamation"></i> Edited by Admin
          </div>
        ) : null}
      </div>
    );
  };
  const buttonLabel = () => {
    return <span>Read more . . .</span>;
  };
  const closeButtonTitle = () => {
    return <span>Done Reading</span>;
  };
  // END Modal Stuff

  return blogs.loading ? (
    <h2>Loading </h2>
  ) : blogs.error ? (
    <h2>{blogs.error}</h2>
  ) : (
    <div>
      <div className="searchWrapper">
        <div>
          <SearchInput
            type="search"
            placeholder="Search a Title..."
            handleSearch={(e) =>
              setSearchField(e.target.value, setIsDisabled(false))
            }
            className={searchField.length < 3 ? 'invalid' : 'entered'}
            value={searchField}
          />

          <button
            onClick={() => setSearchField('', setIsDisabled(true))}
            disabled={isDisabled}
          >
            Clear Search
          </button>
        </div>
        <div className="blogNumber">{filteredBlogs.length} blog to read.</div>
      </div>

      <hr />

      {filteredBlogs &&
        filteredBlogs &&
        filteredBlogs.map((blog, index) => (
          <React.Fragment key={blog.id}>
            <div>
              <h1
                className="search-title"
                dangerouslySetInnerHTML={{ __html: matchName(blog.heading) }}
              />

              <div className="blog-content-wrapper">
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: blog.message.slice(0, 200) + '...',
                    }}
                  ></p>
                  <p className="userDetails">Post by: {blog.name}</p>
                  <Modal
                    title={buttonLabel()}
                    content={modalContent(
                      blog.id,
                      blog.heading,
                      blog.message,
                      blog.posted,
                      blog.name,
                      blog.likes,
                      blog.dislikes,
                      blog.admin,
                    )}
                    closeButtonTitle={closeButtonTitle()}
                  />
                </div>
              </div>

              {blog.admin === '1' ? (
                <div className="adminMessage">
                  <i className="fas fa-exclamation"></i> Edited by Admin
                </div>
              ) : null}
            </div>
            <hr />
          </React.Fragment>
        ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    blogs: state.getReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBlogsRequest: () => dispatch(getBlogsRequest()),
    postLikeRequest: (data) => dispatch(postLikeRequest(data)),
    postDisLikeRequest: (data) => dispatch(postDisLikeRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeBlog);
