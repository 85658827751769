export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_BLOGS_FAILURE = 'GET_BLOGS_FAILURE';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAILURE = 'DELETE_BLOG_FAILURE';
export const PUT_BLOG_SUCCESS = 'PUT_BLOG_SUCCESS';
export const PUT_BLOG_FAILURE = 'PUT_BLOG_FAILURE';
export const POST_BLOG_SUCCESS = 'POST_BLOG_SUCCESS';
export const POST_BLOG_FAILURE = 'POST_BLOG_FAILURE';
export const POST_LIKE_SUCCESS = 'POST_LIKE_SUCCESS';
export const POST_LIKE_FAILURE = 'POST_LIKE_FAILURE';
export const POST_DIS_LIKE_SUCCESS = 'POST_DIS_LIKE_SUCCESS';
export const POST_DIS_LIKE_FAILURE = 'POST_DIS_LIKE_FAILURE';
export const POST_CONTACT_FORM_SUCCESS = 'POST_CONTACT_FORM_SUCCESS';
export const POST_CONTACT_FORM_FAILURE = 'POST_CONTACT_FORM_FAILURE';
